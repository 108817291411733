<script setup lang="ts">
import StyledModal from "@/components/_elements/modals/StyledModal.vue";
import { XMarkIcon } from "@heroicons/vue/20/solid";

const localKey = "clover.prep-announcement-modal";
const show = localStorage.getItem(localKey) !== "false";

const onClose = () => {
	localStorage.setItem(localKey, "false");
};

const redirect = (to) => {
	onClose();
	window.location.href = to;
};
</script>

<template>
	<StyledModal :start-open="show" @on-close="onClose">
		<template v-slot:panel="slotProps">
			<button
				class="fixed top-0 right-0"
				@click="slotProps.events?.closeModal()">
				<XMarkIcon
					class="rounded-full bg-white w-12 h-12 p-1 text-black"></XMarkIcon>
				<span class="sr-only">Close</span>
			</button>

			<img
				src="/img/prep-announcement-modal-image.jpg"
				class="w-full h-auto"
				alt="" />

			<div class="text-center text-pretty px-2 mt-6">
				<h2 class="mt-0">Updated Prep Reporting is Here!</h2>
				<p class="text-base text-gray-700">
					Drive better outcomes with real-time insights into student
					performance across Prep exams and assignments.<br />
					<a
						class="text-blue-700 hover:no-underline hover:text-blue-800"
						href="https://help.cloverlearning.com/hc/en-us/articles/30437464712603-Prep-Reporting-Beta-Walk-Through"
						target="_blank"
						>Learn more about our Prep Reporting update!</a
					>
				</p>

				<div class="flex mt-8 gap-2 items-center justify-center">
					<button
						@click="
							redirect('https://pulse.cloverlearning.com/prep')
						"
						class="hover:cursor-pointer button no-underline bg-blue-700 text-white rounded-md border-none hover:bg-blue-800 hover:text-white">
						Access Prep Reporting Now
					</button>
				</div>
			</div>
		</template>
	</StyledModal>
</template>
